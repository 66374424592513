import { axiosInstance } from "../../network/apis";
import { API } from "../../utils/Constants";

export const getTimeSlots = async (payload) => {
  try {
    const response = await axiosInstance.post(
      API.ENDPOINTS.GET_TIME_SLOTS,
      payload,
      { handlerEnabled: false } // Assuming you want to include this property in the request
    );

    return response;
  } catch (error) {
    // Handle errors here
    console.error("Error fetching time slots:", error);
    return error;
  }
};
