import React from "react";
import { Route, Routes, redirect, useRouteMatch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import history from "./History";
import * as LazyComponent from "../utils/LazyLoaded";
const CustomRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<LazyComponent.Home />} />
        <Route
          path="/booking-status"
          element={<LazyComponent.BookingStatus />}
        />
      </Routes>
    </Router>
  );
};

export default CustomRoutes;
