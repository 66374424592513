import axios from "axios";
import { API } from "../../utils/Constants";
import {
  errorHandler,
  requestHandler,
  successHandler,
} from "../interceptors/index";

export const axiosInstance = axios.create({
  baseURL: `${API.BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((request) => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
