import * as types from "./SnackBarType";

export const showSnackbarAction = (message, snackbarType) => {
  return {
    type: types.SHOW_SNACKBAR,
    message,
    snackbarType,
  };
};

export const hideSnackbarAction = () => {
  return {
    type: types.HIDE_SNACKBAR,
  };
};

export const addGroupRefId = (refId) => {
  return {
    type: types.ADD_GROUP_REF,
    refId,
  };
};
export const addGroupRefIdWithMsg = (refId, refMessage) => {
  return {
    type: types.ADD_GROUP_REF_WITH_MSG,
    refId,
    refMessage,
  };
};
