import * as types from "./TimeAndDateTypes";

const INITIAL_STATE = {
  timeSlotsInProcess: false,
  timeSlots: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TIME_SLOT_IN_PROCESS:
      return {
        ...state,
        timeSlotsInProcess: action.payload,
      };
    case types.RECEIVE_TIME_SLOTS:
      return {
        ...state,
        timeSlots: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
