import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { watchSagas } from "./sagas";
import { loadState, saveState } from "./localStorage";
import reducers from "./reducers";

const saga = createSagaMiddleware();
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(saga));
const persistedState = loadState();
const store = createStore(reducers, persistedState, enhancer);

store.subscribe(() =>
  saveState({
    table: store.getState().table,
    timeslots: store.getState().timeSlots,
  })
);

saga.run(watchSagas);
export default store;
