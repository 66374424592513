import { combineReducers } from "redux";

import table from "../Table/TableReducer";
import loader from "../Loader/LoaderReducers";
import theme from "../Theme/ThemeReducer";
import timeSlots from "../TimeAndDate/TimeAndDateReducer";
import user from "../User/UserReducer";
import snackbar from "../SnackBar/SnackBarReducer";

export default combineReducers({
  table,
  loader,
  theme,
  timeSlots,
  user,
  snackbar,
});
