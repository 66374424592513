import React, { useEffect } from "react";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import App from "./App";
import { create } from "jss";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import { CookiesProvider } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "./store/Theme/ThemeActions";

const defaultThemeConfig = {
  spacing: 10,
  shape: {
    borderRadius: 10,
  },
  breakpoints: {
    values: {
      xs: 0,
      // sm: 710,
      sm: 830,
      md: 1024,
      lg: 1280,
    },
  },
  overrides: {},
};

const ThemeApp = () => {
  const jss = create({ plugins: [...jssPreset().plugins] });
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  const configureTheme = ({ fonts, color }) => {
    return createTheme({
      ...defaultThemeConfig,
      typography: {
        htmlFontSize: 16,
        fontFamily: "Poppins-Regular, sans-serif",
        poppins: fonts.poppins,
        rockwell: fonts.rockwell,
        ...fonts.fontWeights,
      },
      palette: {
        primary: {
          main:
            localStorage.getItem("themecolor") != null
              ? localStorage.getItem("themecolor")
              : color?.primary
              ? color?.primary
              : "#14213d",
          // main: "#a2d2ff",

          // main: "#9C0A35",
        },
        secondary: {
          main: color.secondary,
          // main: "#000000"
        },
        // background: color.background,

        common: {
          background: "#000000",
          white: "#131719",
          lighterBg: "#1E2427",
          fontTitle: "#ffffff",
          normalTitle: "#000000",
          black: "#fff",
          subTitle: "#000000",
          scrollBar: "#f5f5f5",
          scrollBarIdle: "#888",
          whiteTitle: "#FFFFFF",
          headerText: "000",
          red: color.red,
          green: color.green,
          grey: "#414A50",
          lightGrey: "#c6c6c6",
        },
      },
    });
  };

  useEffect(() => {
    dispatch(getTheme());
  }, [dispatch]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={configureTheme(theme)}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeApp;
