import * as types from "./UserType";

const INITIAL_STATE = {
  selectedDate: null,
  peopleCount: null,
  startTime: null,
  endTime: null,
  startTimeUtc: null,
  selectedDuration: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_START_TIME:
      return {
        ...state,
        startTime: action.payload,
      };
    case types.SET_END_TIME:
      return {
        ...state,
        endTime: action.payload,
      };
    case types.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case types.SET_PEOPLE_COUNT:
      return {
        ...state,
        peopleCount: action.payload,
      };
    case types.SET_START_TIME_UTC:
      return {
        ...state,
        startTimeUtc: action.payload,
      };
    case types.REMOVE_END_TIME:
      return {
        ...state,
        endTime: null,
      };
    case types.SET_SELECTED_DURATION:
      return {
        ...state,
        selectedDuration: action.payload,
      };
    case types.CLEAR_USER_VALUES:
      return {
        ...state,
        selectedDate: null,
        peopleCount: null,
        startTime: null,
        endTime: null,
        startTimeUtc: null,
        selectedDuration: null,
      };
    default:
      return state;
  }
};

export default reducer;
