import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ThemeApp from "./Theme";
import { Provider } from "react-redux";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import store from "./store";
import { BUGSNAG_KEY } from "./utils/Constants";
import BugsnagPerformance from "@bugsnag/browser-performance";

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: BUGSNAG_KEY });
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeApp />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);
