import * as types from "./SnackBarType";

const INITIAL_STATE = {
  isOpen: false,
  anchorOrigin: { vertical: "top", horizontal: "center" },
  message: null,
  type: null,
  refGroupId: null,
  refGroupMessage: null,
  openModalWarning: false,
  // showGoToHome: false,
  modalWarningMessage: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_SNACKBAR:
      return {
        ...state,
        isOpen: true,
        message: action.message,
        type: action.snackbarType,
      };
    case types.HIDE_SNACKBAR:
      return {
        ...state,
        isOpen: false,
      };
    case types.ADD_GROUP_REF:
      return {
        ...state,
        refGroupId: action.refId,
      };
    case types.ADD_GROUP_REF_WITH_MSG:
      return {
        ...state,
        refGroupId: action.refId,
        refGroupMessage: action.refMessage || null,
      };
    default:
      return state;
  }
};

export default reducer;
