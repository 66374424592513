import { call, put, takeLatest } from "redux-saga/effects";

import { getTheme } from "./ThemeApi";
import * as ACTIONS from "./ThemeActions";
// import { dispatchSnackbarError } from "../../utils/Shared";
import * as TYPES from "./ThemeType";

// Replace with your sagas
export function* sagasThemeRequest() {
  try {
    const response = yield call(getTheme);
    yield put(ACTIONS.getThemeReceive(response?.data));
  } catch (err) {
    // dispatchSnackbarError(err.response.data);
    // dispatchSnackbarError("Something went wrong, please try again later");
  }
}

export function* ThemeSaga() {
  yield takeLatest(TYPES.GET_THEME_REQUEST, sagasThemeRequest);
}
