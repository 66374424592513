import * as types from "./TableTypes";

const INITIAL_STATE = {
  hotelKey: null,
  hotel: null,
  isHotelFetchCompleted: false,
  isTableRequestPending: false,
  isTableBookingLoading: false,
  tableBookingStatus: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_TABLE_ORDER_REQUEST:
      return {
        ...state,
        hotelKey: action.payload,
        isHotelFetchCompleted: false,
        hotel: null,
      };
    case types.GET_TABLE_ORDER_RECEIVE:
      return {
        ...state,
        isHotelFetchCompleted: true,
        hotel: action.payload,
      };
    case types.SET_TABLE_REQUEST_PENDING:
      return {
        ...state,
        isTableRequestPending: action.payload,
      };
    case types.TABLE_BOOKING_REQUEST:
      return {
        ...state,
        isTableBookingLoading: true,
      };
    case types.TABLE_BOOKING_SUCCESS:
      return {
        ...state,
        isTableBookingLoading: false,
        tableBookingStatus: action.payload,
      };
    case types.TABLE_BOOKING_FAILURE:
      return {
        ...state,
        isTableBookingLoading: false,
        tableBookingStatus: action.payload,
      };
    case types.CLEAR_TABLE_BOOKING:
      return {
        ...state,
        tableBookingStatus: null,
      };
    default:
      return state;
  }
};

export default reducer;
