import React, { Suspense } from "react";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoutes from "./routes/CustomRoutes";
import history from "./routes/History";
import FallBack from "./components/FallBack/FallBack";
import MaterialSnackbar from "./components/Snackbar.js/Snackbar";

function App() {
  return (
    <React.Fragment>
      <Suspense fallback={<FallBack />}>
        <MaterialSnackbar />
        <CustomRoutes />
      </Suspense>
    </React.Fragment>
  );
}

export default App;
