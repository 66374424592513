import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Material
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
// Icons
// import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// Store
// Styles
import useStyles from "./Snackbar.style.js";
import { hideSnackbarAction } from "../../store/SnackBar/SnackBarAction.js";

function MaterialSnackbar(props) {
  const classes = useStyles();
  const { isOpen, message, anchorOrigin, type } = useSelector(
    (state) => state.snackbar
  );
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbarAction());
  };
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={anchorOrigin}
      autoHideDuration={4000}
      onClose={handleClose}
      key={"1"}
      classes={{
        anchorOriginTopRight: classes.anchorOriginTopRight,
      }}
    >
      <div
        className={`${classes.root} ${
          type !== "success" ? classes.warning : classes.success
        }`}
      >
        {/* <FiberManualRecordIcon classes={{ root: classes.icon }} /> */}
        <Typography className={classes.text} variant="caption">
          {message}
        </Typography>
      </div>
    </Snackbar>
  );
}

export default MaterialSnackbar;
