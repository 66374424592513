import * as types from "./TimeAndDateTypes";

export const getTimeSlots = (payload) => ({
  type: types.GET_TIME_SLOTS,
  payload,
});

export const timeSlotsInProcess = (payload) => ({
  type: types.TIME_SLOT_IN_PROCESS,
  payload,
});

export const receiveTimeSlots = (payload) => ({
  type: types.RECEIVE_TIME_SLOTS,
  payload,
});
