export const APP_NAME = process.env.REACT_APP_NAME;

export const API = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  VERSION: "",
  ENDPOINTS: {
    GET_TABLE_BOOKING: "get_table_booking_config",
    GET_TABLE_BOOKING_MAIL: "place_table_booking",
    GET_TIME_SLOTS: "check_table_booking_slots",
  },
};

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const BUGSNAG_KEY = "5fdbd5fff3f07e8cb8442fa39a0cfbac";
export const BOOKINGS_STATUS = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
};

export const timeDurations = [
  {
    id: "282",
    duration: "15",
  },
  {
    id: "292",
    duration: "30",
  },
  {
    id: "252",
    duration: "45",
  },
  {
    id: "232",
    duration: "60",
  },
];

export const ERROR_TYPES = {
  DEFAULT: "DEFAULT",
};
