import * as types from "./LoaderTypes";

const INITIAL_STATE = {
  isLoading: false,
  apiCalls: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_LOADER:
      state.apiCalls.push(action.data);
      return {
        ...state,
        isLoading: state.apiCalls.length > 0,
      };
    case types.HIDE_LOADER:
      state.apiCalls.shift();
      return {
        ...state,
        isLoading: state.apiCalls.length > 0,
      };
    default:
      return state;
  }
};

export default reducer;
