import * as types from "./ThemeType";

export const getTheme = () => ({
  type: types.GET_THEME_REQUEST,
});

export const getThemeReceive = (payload) => ({
  type: types.GET_THEME_RECEIVE,
  payload,
});
