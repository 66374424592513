import { call, put, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./TimeAndDateActions";
import * as TYPES from "./TimeAndDateTypes";
import { getTimeSlots } from "./TimeAndDateApi";

export function* sagasTimeSlotsRequest({ payload }) {
  try {
    yield put(ACTIONS.timeSlotsInProcess(true));
    const response = yield call(getTimeSlots, payload);

    if (!response?.data?.status) {
      throw new Error("Something went wrong");
    }
    // Dispatch your success action
    yield put(ACTIONS.receiveTimeSlots(response?.data));
    yield put(ACTIONS.timeSlotsInProcess(false));
  } catch (error) {}
}

export function* TimeSlotsSaga() {
  yield takeLatest(TYPES.GET_TIME_SLOTS, sagasTimeSlotsRequest);
}
